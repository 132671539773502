@import "./../../utils/styles/common.module.scss";

.footerContainer {
    background-color: #180F3D;
    min-height: 200px;
    flex-direction: column;
    
    h3 {
        color: rgb(255, 255, 255);
    }
} 

.footerContent {
    color: white;
    padding: 0px 40px;
    height: 100%;
    display: flex;
    flex-direction: row;

    .col {
        display: flex;
        flex: 1;
    }

    h3 {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.faIcon {
    margin-left: 15px;
}

.faChevronUp {
    position: relative;
    color: white;
    font-size: 17px;
}

.toTopIcon {
    cursor: pointer;
    outline: none;
    border: thin solid rgb(156, 142, 211);
    height: 35px;
    width: 35px;
    align-self: flex-end;
    margin-top: 7px;
    margin-right: 30px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0);
}

.credit {
    background-color: rgb(37, 26, 82);
    border-top: thin solid rgb(37, 26, 82);
    height: 50px;
    margin-top: 24px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: row;

    p {
        margin-top: 17px;
        padding-left: 40px;
        color: rgb(118, 104, 170);
        font-size: 13px;
    }
}

.creditContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.linksList {
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none !important;

    h4 {
        font-size: 18px;
        color: rgb(93, 77, 148);
    }

    a {
        text-decoration: none;
    }

    li {
        // padding: 5px 0px;
        text-align: left;
        transition: 0.2s;
    }

    li:hover {
        opacity: 0.5;
    }

    p {
        font-size: 14px;
        padding: 5px 0px;
        margin: 0px;
        color: rgb(216, 216, 216);
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
