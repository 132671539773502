.fadeIn {
    -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.3s; /* Firefox < 16 */
        -ms-animation: fadein 0.3s; /* Internet Explorer */
         -o-animation: fadein 0.3s; /* Opera < 12.1 */
            animation: fadein 0.3s;
}

.subtleShadow {
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.09);
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.underline {
    height: 3px;
    width: 60px;
    background-color: #F9AB14;
    margin-top: -20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}


html, body {
    overflow-x: hidden;
}

h1, h2 {
    font-family: 'Staatliches', cursive;
    font-size: 40px;
}

h3, h4 {
    font-family: 'Heebo', sans-serif;
    font-size: 24px;
}

p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 21px;
}

