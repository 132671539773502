@import './../../utils/styles/common.module.scss';

.purposeContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.headerContainer {
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    height: 100%;
    min-height: 400px;
    margin-top: 20px;

    @media screen and (max-width: 666px) {
        flex-direction: column;
    }
}

.imageContainer {
    width: 50%;
    padding: 10px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 700px) and (min-width: 667px) {
        padding: 0px;
    }

    @media screen and (max-width: 666px) {
        width: 100%;
        padding: 0px;
    }
}

.image {
    @extend .subtleShadow;
    position: relative;
    height: auto;
    max-width: 100%;
}

.descContainer {
    width: 50%;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;

    @media screen and (max-width: 700px) and (min-width: 667px) {
        padding: 0px;
    }

    @media screen and (max-width: 666px) {
        width: 100%;
        padding: 0px;
    }
}

.imageContent {
    padding: 10px 20px;
}

.descContent {
    padding: 10px 20px;
}
