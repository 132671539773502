.jumbotronContainer {
    background-color: #180F3D;
    height: 100vh;
    display:flex;
    color: white;
    background-image: url('./../../assets/jumboi.jpg');
    background-size: cover;
    background-position: center center;
}

.particlesContainer {
    position: absolute;
    width: 100%;
    height: auto;

    canvas {
        height: 100vh !important;
    }
}

.underline {
    height: 3px;
    width: 60px;
    background-color: #F9AB14;
    margin-bottom: 8px;
    margin-top: -10px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.discoverCta {
    padding: 15px 0px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100px;
    margin-top: 20px;
    border: 3px solid white;
    color: white;
    background-color: rgba(0,0,0,0);
    transition: 0.3s;
    font-size: 13px;
    letter-spacing: 3px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer;

    span {
        font-weight: bold;
    }

    &:hover {
        background-color: white;
        color: black;
    }
}

.fadeIn {
    -webkit-animation: fadein 0.7s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.7s; /* Firefox < 16 */
        -ms-animation: fadein 0.7s; /* Internet Explorer */
         -o-animation: fadein 0.7s; /* Opera < 12.1 */
            animation: fadein 0.7s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.headerContainer {
    @extend .fadeIn;
    z-index: 1;
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    text-align: center;
    height: auto;
}

.headerContent {
    display: flex;
    flex-direction: column;
    height: auto;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 24px;

    h1 {
        letter-spacing: 4px;
        font-size: 50px;

        @media screen and (max-width: 540px) {
            letter-spacing: 2px;
            font-size: 45px;
        }
    }

    p {
        font-family: 'Thasadith', sans-serif;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
        
        @media screen and (max-width: 540px) {
            font-size: 18px;
        }
    }
}

canvas {
    height: 80vh !important;
}   
