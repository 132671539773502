@import './../../utils/styles/common.module.scss';

.impactContainer {
    min-height: 400px;
}

.carouselContainer {
    min-height: 350px;
    display: flex;
    flex-direction: row;
}

.leftCta button, .rightCta button {
    cursor: pointer;
    transition: 0.3s;
    outline-color: white;

    &:hover {
        opacity: 0.6;
    }
}

.leftCta {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-end;
    font-size: 30px;

    button {
        font-size: 30px;
        background-color: white;
        border: none;
    }
}

.activeDot {
    background-color: black;
}

.dot {
    transition: 1s;
}

.center {
    text-align: center;
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    height: auto;
}

.testimonials {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.activeQuote {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
}

.centerContent {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
}

.rightCtaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    text-align: center;
}

.leftCtaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    text-align: center;
}

.rightCta {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    button {
        font-size: 30px;
        background-color: white;
        border: none;
    }
}

.quoteContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    height: 90%;
}

.quote, .saidBy {
    font-family: "Adobe Caslon Pro", "Hoefler Text", Georgia, Garamond, Times, serif;
    letter-spacing:0.1em;
    line-height: 145%;
    font-size: 1.5em;
    font-variant: small-caps;
}

.saidBy {
    font-size: 1.3em;
    font-weight: bold;
}

.rightCtaContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dotsContainer {
    align-self: flex-end;
    width: 80px;
    margin-left: auto;
    margin-right: auto;

    ul {
        display: flex;
        list-style: none;
        padding: 0px !important;
        
        li {
            display: flex;
            height: 8px;
            width: 8px;
            border-radius: 50px;
            margin-left: auto;
            margin-right: auto;
            border: thin solid black;

            a {

            }
        }
    }
}
