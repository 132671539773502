@import './../../utils/styles/common.module.scss';

.talentContainer {
    height: 100%;
    min-height: 520px;
}

.primaryTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerContainer {
    min-height: 210px;
    background-color: rgb(211, 227, 235);
    padding: 0px 20px 30px 20px;
}

.primaryTextContent {
    max-width: 800px;
    text-align: center;
    margin-top: -10px;

    p {
        font-family: 'Aleo', serif !important;
        font-size: 20px;
        color: #434343;
        line-height: 1.3em;
        color: rgb(61, 98, 114);
    }

    @media screen and (max-width: 1024px) {
        text-align: left;
    }
}

.talentGridContainer {
    display: flex;

    @media screen and (max-width: 1024px) {
        display: block;
    }
}

.iconContainer {
    text-align: center;
    font-size: 35px;

    @media screen and (max-width: 1024px) {
        font-size: 45px;
    }
}

.talentItem {
    flex: 1;
    padding: 20px 30px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 1024px) {
        height: 200px;
    }

    .talentTitle {
        font-size: 20px;
    }
}
