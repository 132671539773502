@import './../../utils/styles/common.module.scss';

.hqContainer {
    background: rgb(2,0,36);
    background: -moz-linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(210,233,255,1) 0%, rgba(255,255,255,1) 58%);
    background: -webkit-linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(210,233,255,1) 0%, rgba(255,255,255,1) 58%);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(210,233,255,1) 0%, rgba(255,255,255,1) 58%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#ffffff",GradientType=1);
    min-height: 400px;
}

.innerContainer {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.headerContainer {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -65px;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    position: relative;
}

.headline {
    border: 6px solid rgba(253, 88, 76, 0.6);
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px 20px 20px 20px;
    text-align: center;

    p {
        font-family: 'Aleo', serif !important;
        font-size: 24px;
        color: #434343;
        line-height: 1.3em;
    }
}

.citiesContainer {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 50px;
    padding-top: 30px;

    @media screen and (max-width: 794px) {
        overflow-x: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }
}

.cities {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
}

.cityItem {
    @extend .subtleShadow;
    background-size: cover;
    color: white;
    background-position: center;
    flex: 0 0 auto;
    margin: 30px;
    border-radius: 100px;
    height: 200px;
    width: 200px;
    background-color: white;
    display: flex;
    align-items: center;
    transition: 0.3s;

    &:hover {
        opacity: 0.8;
    }

    .contentContainer {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        text-align: center;
        border-radius: 100px;
        width: 100%;
        text-align: center;
        background-color: rgba(0,0,0,0.3);
        border: thin solid rgba(0,0,0,0);
        transition: 0.3s;

        &:hover {
            border: thin solid rgb(255, 142, 66);
        }

        p {
            width: 100%;
            text-align: center;
            font-size: 21px;
        }
    }
}
